import React, { useState } from 'react'
import PriceChecklistComponent from './PriceChecklistComponent'

const OurPriceComponent = ({type, handleScroll}) => {
    
  const [service, setService] = useState()
  const [list, setList] = useState(false)
  
  const bookForm = () => {
    setList(false)
    handleScroll()
  }
  
    
  return (
    <div>


{
        list?
        <PriceChecklistComponent bookForm={bookForm}  open={setList} type={type}  service={service}/>
        :""
      }
        



        {
        type === "At-Home"?


<div className="package-price-content-col-2-content">
              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                  {type} Regular Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  Below 125 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 599 </s>&nbsp; Rs. 299 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Change</li>
                      <li>Oil Filter Clean</li>
                      <li>Air Filter Clean</li>
                      <li>Spark Plug Clean</li>
                    </ul>                    
                  </div>
                  <div onClick={e=>(setService("regular") || setList(true))} className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                {type} Classic Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  125 CC to 199 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 799 </s>&nbsp; Rs. 399 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Change</li>
                      <li>Oil Filter Clean</li>
                      <li>Air Filter Clean</li>
                      <li>Spark Plug Clean</li>
                    </ul>                  </div>
                  <div  onClick={e=>(setService("classic") || setList(true))}  className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                {type} Premium Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  200 CC to 299 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 1,199 </s>&nbsp; Rs. 499 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Change</li>
                      <li>Oil Filter Clean</li>
                      <li>Air Filter Clean</li>
                      <li>Spark Plug Clean</li>
                    </ul>                  </div>
                  <div onClick={e=>(setService("premium") || setList(true))}  className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                {type} Royal Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  300 CC to 349 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 1,599 </s>&nbsp; Rs. 599 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Change</li>
                      <li>Oil Filter Clean</li>
                      <li>Air Filter Clean</li>
                      <li>Spark Plug Clean</li>
                    </ul>                  </div>
                  <div onClick={e=>(setService("royal") || setList(true))}  className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                {type} Sports Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  Above 350 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 2,199 </s>&nbsp; Rs. 999 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Change</li>
                      <li>Oil Filter Clean</li>
                      <li>Air Filter Clean</li>
                      <li>Spark Plug Clean</li>
                    </ul>                  </div>
                  <div onClick={e=>(setService("sports") || setList(true))}  className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

            </div>

            
: type === "Road Side"?

  
<div className="package-price-content-col-2-content">
              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                  {type} Regular Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  Below 125 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 599 </s>&nbsp; Rs. 299 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Check-up</li>
            <li>Air Filter Check-up</li>
            <li>Spark Plug Check-up</li>
            <li>Brakes – Front & Rear Check-up</li>
                    </ul>                    
                  </div>
                  <div onClick={e=>(setService("regular") || setList(true))} className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                {type} Classic Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  125 CC to 199 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 799 </s>&nbsp; Rs. 399 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Check-up</li>
            <li>Air Filter Check-up</li>
            <li>Spark Plug Check-up</li>
            <li>Brakes – Front & Rear Check-up</li>
                    </ul>                  </div>
                  <div  onClick={e=>(setService("classic") || setList(true))}  className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                {type} Premium Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  200 CC to 299 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 1,199 </s>&nbsp; Rs. 499 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Check-up</li>
            <li>Air Filter Check-up</li>
            <li>Spark Plug Check-up</li>
            <li>Brakes – Front & Rear Check-up</li>
                    </ul>                  </div>
                  <div onClick={e=>(setService("premium") || setList(true))}  className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                {type} Royal Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  300 CC to 349 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 1,599 </s>&nbsp; Rs. 599 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Check-up</li>
            <li>Air Filter Check-up</li>
            <li>Spark Plug Check-up</li>
            <li>Brakes – Front & Rear Check-up</li>
                    </ul>                  </div>
                  <div onClick={e=>(setService("royal") || setList(true))}  className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

              <div className="package-price-content-col-2-col">
                <div className="package-price-content-col-2-col-heading-1">
                {type} Sports Service
                </div>
                <div className="package-price-content-col-2-col-heading-2">
                  Above 350 CC
                </div>
                <div className="package-price-content-col-2-col-heading-3">
                  <s>Rs. 2,199 </s>&nbsp; Rs. 999 /-
                </div>
                <div className="package-price-content-col-2-col-content">
                  <div className="package-price-content-col-2-col-points">
                    <ul>
                      <li>Engine Oil Check-up</li>
            <li>Air Filter Check-up</li>
            <li>Spark Plug Check-up</li>
            <li>Brakes – Front & Rear Check-up</li>
                    </ul>                  </div>
                  <div onClick={e=>(setService("sports") || setList(true))}  className="package-price-content-col-2-col-button">
                    See checklist
                  </div>
                </div>
              </div>

            </div>


:""}

        


    </div>
  )
}

export default OurPriceComponent