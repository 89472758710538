import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../../media/images/logo.png"

const LandingFooter = () => {
  return (
    <div>
           <div className='landing-page-footer'>

<div className='landing-page-footer-content'>

    <div className='landing-page-footer-col-1'>
        {/* <div className='landing-page-footer-col-1-logo'><Link to='/'><span> <i className="fa-solid fa-screwdriver-wrench"></i> Garage</span> On Call</Link></div>
        <div className='landing-page-footer-col-1-tagline'>ride with happiness</div> */}

        <div className="footer-logo">
            <img title='logo' src={logo} alt="logo" />
        </div>
        
        {/* <div className='landing-page-footer-col-1-address-heading'>Corporate Office</div>
        <div className='landing-page-footer-col-1-address-text'>210, 2nd Floor, Ocean Plaza, Sector-18, Noida - 201301</div> */}
    </div>

    <div className='landing-page-footer-col-2'>


        <div className='landing-page-footer-col-2-list'>
            <div className='landing-page-footer-col-2-list-heading'>Social Media</div>
            <div className='landing-page-footer-col-2-list-points'>
                {/* <div>asdfasd</div>
                <div>asdfasd</div> */}
                <div><span>&#10095;&#10095; &ensp; </span><Link title='contact link' to="https://www.facebook.com/garageoncallofficial/" target='_blank'><i className="fa-brands fa-facebook-f"></i> &ensp; <span>FaceBook</span></Link></div>
                <div><span>&#10095;&#10095; &ensp; </span><Link title='contact link' to="https://twitter.com/i/flow/login?redirect_after_login=%2FGarageoncall" target='_blank'><i className="fa-brands fa-x-twitter"></i> &ensp; <span>Twitter</span></Link></div>
                <div><span>&#10095;&#10095; &ensp; </span><Link title='contact link' to="https://www.instagram.com/garageoncallofficial/" target='_blank'><i className="fa-brands fa-instagram"></i> &ensp; <span>Instagram</span></Link></div>
                <div><span>&#10095;&#10095; &ensp; </span><Link title='contact link' to="https://www.linkedin.com/company/garage-on-call/" target='_blank'><i className="fa-brands fa-linkedin-in"></i> &ensp; <span>Linkedin</span></Link></div>
                <div><span>&#10095;&#10095; &ensp; </span><Link title='contact link' to="https://www.youtube.com/@GarageOnCall" target='_blank'><i className="fa-brands fa-youtube"></i> &ensp; <span>YouTube</span></Link></div>
            </div>
        </div>


        <div className='landing-page-footer-col-2-list'>
            <div className='landing-page-footer-col-2-list-heading'>Available In</div>
            <div className='landing-page-footer-col-2-list-points'>
                <div><span>&#10004; </span> &ensp; Doorstep Bike Service Delhi</div>
                <div><span>&#10004; </span> &ensp; Doorstep Bike Service Noida</div>
                <div><span>&#10004; </span> &ensp; Doorstep Bike Service Greater Noida</div>
                <div><span>&#10004; </span> &ensp; Doorstep Bike Service Gurugram</div>
                <div><span>&#10004; </span> &ensp; Doorstep Bike Service Ghaziabad</div>
                <div><span>&#10004; </span> &ensp; Doorstep Bike Service Faridabad</div>
                {/* <div><span>&#10004; </span> &ensp; Doorstep Bike Service Chandigarh</div> */}
            </div>
        </div>

        



    </div>




</div>
</div>
    <div className='footer-cc'>Copyright <i className="fa-regular fa-copyright"></i> 2019, Garage on Call. All Rights Reserved.</div>
    </div>
  )
}

export default LandingFooter