import React, { useRef } from 'react'
// import LandingNav from '../../components/user/LandingNav'
import LandingHeader from '../../components/user/LandingHeader'
import LandingLocation from '../../components/user/LandingLocation'
import LandingPrice from '../../components/user/LandingPrice'
// import OurService from '../../components/user/OurService'
import OurWork from '../../components/user/OurWork'
import Testimonial from '../../components/user/Testimonial'
import LandingFooter from '../../components/user/LandingFooter'
import ButtonComponent from '../../components/user/ButtonComponent'
// import KeyWords from '../../components/user/KeyWords'
import ClientSaysComponent from '../../components/user/ClientSaysComponent'
import { Helmet } from 'react-helmet';
import PricePageComponent from '../../components/user/PricePageComponent'

const LandingPage = () => {

   // Create a ref to the div you want to scroll to
   const targetDivRef = useRef(null);

   // Function to handle the scroll on button click
   const handleScroll = () => {
     if (targetDivRef.current) {
       targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
     }
   };
  
  return (
    <div>

      
      <Helmet>
        <title>Garage on call offers Bike servicing at home in Noida and Delhi</title>
        <meta name="description" content="
        Garage on call - We offers Bike servicing at home in Noida, Delhi, Gurgaon and Ghaziabad. Call us for doorstep bike servicing and repairing +91 9658124124
        " />
      </Helmet>
      
      
      <div className="landing-page">

        <LandingHeader targetDivRef={targetDivRef}/>
        <ClientSaysComponent/>
        <LandingLocation/>


        <PricePageComponent handleScroll={handleScroll}/>
        
        {/* <LandingPrice/> */}
        {/* <OurService/> */}

        <OurWork/>

        <Testimonial/>
        {/* <KeyWords/> */}
 
        <LandingFooter/>

        <ButtonComponent/>
        
        {/* <div className="landing-page-header">
          <LandingNav/>
        </div> */}
      </div>
    </div>
  )
}

export default LandingPage