import './App.css';
import './css/user/LandingPage.css'
import './css/user/TestimonialPage.css'
import './css/user/Button.css'
import './css/user/Animation.css'
import './css/user/Loading.css'
import './css/user/ClientSaysComponent.css'
import './css/user/PackagePriceComponent.css'


import LandingPage from './pages/user/LandingPage';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ErrorPage from './pages/user/ErrorPage';
import BengaluruLandingPage from './pages/user/BengaluruLandingPage';
import BookedPage from './pages/user/BookedPage';

function App() {
  return (
    <div className='web-app'>
      <BrowserRouter>
        <Routes>
        <Route exact path='/' element={<LandingPage />}></Route>
        {/* <Route exact path='/bengaluru' element={<BengaluruLandingPage />}></Route> */}
        <Route exact path='*' element={<ErrorPage />}></Route>
        <Route path='/booking-confirm' element={<BookedPage />}></Route>


        </Routes>

      </BrowserRouter>


    </div>
  );
}

export default App;
